<template>
  <div class="bubbleBox">
    <div ref="Info" class="Bubble" >
      <span class="lj"><span class="tip"></span></span>

      <div v-if="itemKey == 'patrol_lines'" class="markbox">
        <div class="markTitle">
          <p>
            <span>{{ item.lineName }}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">管线名称：{{ item.lineName }}</el-col>
            <el-col :span="24">使用状态：{{ item.useStatusName }}</el-col>
            <!-- <el-col :span="24">燃气类型：{{item.gasTypeName}}</el-col> -->
            <el-col :span="24">压力级别：{{ item.stressLevelName }}</el-col>
            <el-col :span="24">材质：{{ item.materialName }}</el-col>
            <el-col :span="24">埋设方式：{{ item.buriedType }}</el-col>
            <el-col :span="24">管径：{{ item.bore }}</el-col>
            <el-col :span="24">管线长度：{{ item.length }}</el-col>
            <el-col :span="24" v-if="item.buriedType == '穿越'">穿越方式：{{ item.through }}</el-col>
            <el-col :span="24" v-if="item.buriedType != '架空'">埋深：{{ item.burialDepth }}</el-col>
            <el-col :span="24">建设年度：{{ item.buildDate }}</el-col>
            <el-col :span="24">关联设备统计：{{ item.type }}</el-col>
            <!-- <el-col :span="24" class="bottomBt">
                <el-button plain @click="openDeviceRele(item)" v-if="item.equipmentList&&item.equipmentList.length>0" size="mini">关联设备</el-button>
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'patrol_lines')">删除</el-button>
            </el-col> -->
          </el-row>
        </div>
    </div>
    <div v-if="itemKey == 'gis_line_view'" class="markbox">
        <div class="markTitle">
          <p>
            <span>{{ item.lineName }}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">管线名称：{{ item.lineName }}</el-col>
            <el-col :span="24">使用状态：{{ item.useStatusName }}</el-col>
            <!-- <el-col :span="24">燃气类型：{{item.gasTypeName}}</el-col> -->
            <el-col :span="24">压力级别：{{ item.stressLevelName }}</el-col>
            <el-col :span="24">材质：{{ item.materialName }}</el-col>
            <el-col :span="24">埋设方式：{{ item.buriedTypeName }}</el-col>
            <el-col :span="24">管径：{{ item.bore }}</el-col>
            <el-col :span="24">管线长度：{{ item.length }}</el-col>
            <el-col :span="24" v-if="item.buriedType == '穿越'">穿越方式：{{ item.through }}</el-col>
            <el-col :span="24" v-if="item.buriedType != '架空'">埋深：{{ item.burialDepth }}</el-col>
            <el-col :span="24">建设年度：{{ item.buildDate }}</el-col>
            <el-col :span="24">关联设备统计：{{ item.type }}</el-col>
            <!-- <el-col :span="24" class="bottomBt">
                <el-button plain @click="openDeviceRele(item)" v-if="item.equipmentList&&item.equipmentList.length>0" size="mini">关联设备</el-button>
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'patrol_lines')">删除</el-button>
            </el-col> -->
          </el-row>
        </div>
    </div>
      <div v-if="itemKey == 'patrol_events'" class="markbox" style="min-height:auto;">
        <div class="markTitle">
          <p>
            <!-- <span>{{ item.lineName }}</span> -->
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature" >
          <el-row>
            <!-- <el-col :span="24">燃气类型：{{item.gasTypeName}}</el-col> -->
            <!-- <el-col :span="24">事件类型：{{ item && item.eventCodeName }}</el-col> -->
            <el-col :span="24">事件类型：{{ (item && item.eventCodeIdConfirmName) || item.eventCodeName }}</el-col>
          </el-row>
        </div>
      </div>
      <div v-if="itemKey == 'user_location_jihe'" class="markbox" style="min-height:auto;">
        <div class="markTitle">
          <p>
            <!-- <span>{{ item.lineName }}</span> -->
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature" >
          <el-row>
            <el-col :span="24">人员名称：{{ item && item.username }}</el-col>
            <!-- <el-col :span="24">燃气类型：{{item.gasTypeName}}</el-col> -->
            <!-- <el-col :span="24">最后登录时间：{{ item.updateTime }}</el-col> -->
            <el-col :span="24">手机号：{{ item && item.mobile }}</el-col>
          </el-row>
        </div>
      </div>
    <div v-else-if="itemKey == 'patrol_networks'" class="markbox">
        <div class="markTitle">
          <p>
            <span>{{ item.networkName }}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">管线总长度:{{ (item.length/1000).toFixed(2) }}km</el-col>
            <el-col :span="24">巡检点数量:{{ item.count }}</el-col>
            <el-col :span="24">创建人：{{item.createBy}}</el-col>
            <el-col :span="24">创建时间：{{item.createTime}}</el-col>
            <el-col :span="24" class="bottomBt">
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'patrol_networks_jihe')">删除</el-button>
            </el-col>
          </el-row>
        </div>
    </div>
    <div v-else-if="itemKey == 'patrol_equipments'" class="markbox">
        <div class="markTitle">
          <p>
            <span>{{item &&  item.equipmentName }}</span>
          </p>
          <i class="el-icon-error" @click="close()"></i>
        </div>
        <div class="Feature">
          <el-row>
            <el-col :span="24">设备类型:{{ item && item.equipmentTypeName }}</el-col>
            <el-col :span="24">规格型号：{{ item && item.equipmentSpecifications }}</el-col>
            <el-col :span="24">设备状态：{{equipmentStatusList[item && item.equipmentStatus]}}</el-col>
            <el-col :span="24">生产日期：{{ item && item.equipmentTime}}</el-col>
            <el-col :span="24">使用日期：{{item && item.equipmentUseTime}}</el-col>
            <el-col :span="24">所属部门：{{item && item.deptName}}</el-col>
            <el-col :span="24">生产商：{{item && item.equipmentProducers}}</el-col>
            <el-col :span="24" v-if="item.lineName">关联管线：{{ item.lineName }}</el-col>
            <el-col :span="24">所在地址：{{item && item.equipmentHome}}</el-col>
            <!-- <el-col :span="24" class="bottomBt">
                <el-button type="primary" plain size="mini" @click="editFun(item)">编辑</el-button>
                <el-button type="danger" plain size="mini" @click="delFun(item.id,'patrol_equipments')">删除</el-button>
            </el-col> -->
          </el-row>
        </div>
    </div>



  </div>
    <el-dialog title="查看" :append-to-body="true" :visible.sync="DeviceReleData">
        <div style="max-height:400px;overflow: auto;">
          <el-table  :height="200" :data="item ? item.equipmentList : []">
            <el-table-column
                prop="equipmentName"
                label="设备名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="equipmentTypeName"
                label="设备类型"
                align="center">
            </el-table-column>
            <el-table-column
                prop="equipmentHome"
                label="位置"
                align="center">
            </el-table-column>
          </el-table>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import { queryFeatrue } from '@/apis/geo'
import { lineDetail,lineDelete,networkDetail,delNetwork,equipmentsDetail,delEquipments,userPath} from '@/apis/commonType'
import {
  eventPage,getPersonDetail
} from "@/RequestPort/maintenance";
export default {
  components: {},
  props: {
    layerName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      DeviceReleData:false,
      overlay: null,
      infoWindow: null,
      isInfo: true,
      item: {},
      layerType: 'point',
      itemKey: 'user_location_jihe',
      equipmentStatusList:['停用','在用','废弃'],
      gasTypeList:['煤气','天然气','其他'],
      stressLevelList:['高压','低压','中压','次高压'],
    }
  },
  destroyed() {
    // this.$eventBus.$off("addDian");
  },
  mounted() {
    // this.$eventBus.$on("addDian", (data) => {
    //   // this.type=data.type
    //   // let addForm=JSON.parse(JSON.stringify(data.data)) 
    //   this.getFeatrue(data.lnglat)
      
    // });
  },
  methods: {
    openDeviceRele(){
      this.DeviceReleData = true
    },
    editFun(item){
      this.$parent.$parent.closePDraw()
      let data={
        data:item,
        type:this.itemKey
      }
      this.$eventBus.$emit('vectorEdit', data )
      this.close()
    },
    delFun(id,type){
      this.$confirm(`确定要删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {

          if(type=='patrol_lines'){
            let p = {
                id: id
              }
              lineDelete(p).then(res => {
                if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.close()
                  this.$parent.$parent.reloadList()
                  // this.$parent.$parent.getListArea()
                }
              })
          }else if(type=='patrol_networks_jihe'){
            let p = {id: id }
            delNetwork(p).then(res => {
               if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.close()
                  this.$parent.$parent.reloadList()
                }
            })
          }else if(type=='patrol_equipments'){
            let p = {id: id }
            delEquipments(p).then(res => {
               if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.close()
                  this.$parent.$parent.reloadList()
                }
            })
          }
      })
       
    },
    initialize(e) {
      this.item = e.item
      this.infoWindow = e.infoWindow
    },
    //关闭
    close() {
      this.infoWindow.close()
    },
    getFeatrue(lnglat,layersE) {
      const that = this
      let points = that.centerPointGetFourPoint(lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]

      // let cqlFilter = this.$store.getters.layers ? this.$store.getters.layers : layersE
      // let cql
      // if (cqlFilter) {
      //   cql = { CQL_FILTER: `table_type in(${cqlFilter}) and general_status=1` }
      // }
      // let layerName='gas:checkProject'
      // if(this.layerName){
      //   layerName=this.layerName
      // }
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: 'linqing:lineWalking',
        LAYERS: 'linqing:lineWalking',
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 10,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
      // ...cql,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        propertyName: 'id'
      }
      queryFeatrue(parames).then(res => {
        if (res.features[res.features.lenght -1 ]) {
          let patrolEvents=[]
          let patrolEquipments=[]
          let patrolLines=[]
          let viewLine=[]
          let patrolNetworks=[]
          let patrolPeople = []
           let features=res.features
           features.forEach(item => {
            let id=item.id
            let arr=id.split('.')
            if (arr[0] == 'patrol_events'){
              patrolEvents.push(item.properties)
              return
            }else if(arr[0] == 'patrol_events_jihe') {
              patrolEvents.push(item.properties)
              return
            }
            else if(arr[0] == 'patrol_equipments_jihe') {
              patrolEquipments.push(item.properties)
              return
            }else if(arr[0] == 'gis_line_view') {
              viewLine.push(item.properties)
              return
            }
            else if(arr[0] == 'patrol_lines_jihe') {
              patrolLines.push(item.properties)
              return
            }else if(arr[0] == 'patrol_networks') {
              patrolNetworks.push(item.properties)
              return
            }else if(arr[0] == "user_location_jihe"){
              patrolPeople.push(item.properties)
              return
            }
        })

          let id=res.features[0].id
          let arr=id.split('.')
          if(patrolEvents.length>0) {
            //事件
             this.itemKey = 'patrol_events'
            this.pipeDetail(patrolEvents[0], lnglat)
          }else if(patrolEquipments.length>0) {
            //设备
             this.itemKey = 'patrol_equipments'
             that.getEquipmentsDetail(patrolEquipments[0], lnglat)
          }else if(viewLine.length>0) {
            //管线
             this.itemKey = 'gis_line_view'

             that.getLineDetail(viewLine[0], lnglat)
          }
          else if(patrolLines.length>0) {
            //管线
             this.itemKey = 'patrol_lines'
             that.getLineDetail(patrolLines[0], lnglat)
          }else if(patrolPeople.length>0) {
            this.itemKey = 'user_location_jihe'
            that.peopleDetail(patrolPeople[0], lnglat)
          }
          else if(patrolNetworks.length>0) {
            //片区
             this.itemKey = 'patrol_networks'
             that.getNetworkDetail(patrolNetworks[0], lnglat)
          }
        }
      })
    },
    openFeatrueInfo(feat, lnglat) {
      let html = this.$refs.Info
      let infoWindow = new AMap.InfoWindow({
        position: lnglat,
        offset: new AMap.Pixel(-2, -15),
        anchor: 'top-left',
        content: html
      })
      this.initialize({
        item: feat,
        infoWindow: infoWindow
      })
      infoWindow.open(this.$parent.map)
    },
    /**
     * 获取管线详情
     */
    getLineDetail(data, lnglat) {
      console.log(data)
      let p = {
        id: data.id // data.item_key
      }
      lineDetail(p).then(res => {
        this.openFeatrueInfo(res.data.records[0], lnglat)
      })
    },
    getNetworkDetail(data, lnglat) {
      let p = {
        id: data.id // data.item_key
      }
      networkDetail(p).then(res => {
        console.log(res)
        this.openFeatrueInfo(res.data, lnglat)
      })
    },
  /**
   * 事件
   */
    pipeDetail(data,lnglat){
      eventPage({ids:data.id}).then((e)=>{
        this.openFeatrueInfo(e.data.records[0], lnglat)
      })
    },
    /**
     * 人员
     */
    peopleDetail(data,lnglat){
      userPath({userId:data.id}).then((e)=>{
        this.openFeatrueInfo(e.data.records[0], lnglat)
      })
    },
    getEquipmentsDetail(data, lnglat) {
      let p = {
        equipmentId: data.id // data.item_key
      }
      equipmentsDetail(p).then(res => {
        this.openFeatrueInfo(res.data.records[0], lnglat)
      })
    },
    centerPointGetFourPoint(lnglat) {
      let margin = 100
      console.log()
      let Pixel = this.$parent.map.lngLatToContainer(lnglat)
      let newPixel = new AMap.Pixel(Pixel.x - margin, Pixel.y)
      const newLnglat = this.$parent.map.containerToLngLat(newPixel)

      let side = AMap.GeometryUtil.distance(lnglat, newLnglat)

      const centerPoint = lnglat
      const upLeftPoint = centerPoint.offset(-parseInt(side / 2), parseInt(side / 1.9))
      const upRightPoint = centerPoint.offset(parseInt(side / 2), parseInt(side / 1.9))
      const leftBottomPoint = centerPoint.offset(-parseInt(side / 2), -parseInt(side / 1.7))
      const rightBottomPoint = centerPoint.offset(parseInt(side / 2), -parseInt(side / 1.7))
      return {
        upLeftPoint: [upLeftPoint.lng, upLeftPoint.lat],
        upRightPoint: [upRightPoint.lng, upRightPoint.lat],
        leftBottomPoint: [leftBottomPoint.lng, leftBottomPoint.lat],
        rightBottomPoint: [rightBottomPoint.lng, rightBottomPoint.lat]
      }
    },
    
  }
}
</script>
<style lang="scss">
.amap-info-outer,
.amap-menu-outer {
  box-shadow: none !important;
}
.amap-info-content{padding:0;background: none;}
.top-left .amap-info-sharp{display: none;}
.amap-info-close{display: none;}
</style>
<style lang="scss" scoped>
.bottomBt{margin-top:15px;text-align: center;}
.line-mark {
  width: 4rem;
}
.bubbleBox {
  position: absolute;
  z-index: -1;
}
.Bubble {
  div {
    font-size: 14px;
  }
  
  position: relative;
  .lj {
    display: block;
    position: absolute;
    top: 0.15rem;
    left: -18px;
    transform: rotate(-90deg);
    z-index: 1;
    .tip {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--background-color-skin);
      position: relative;
    }
    .tip:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: -10px;
      left: -10px;
      z-index: -1;
      border-left: 10px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 11px solid #66b1ff;
    }
  }
}


.markbox {
  background: var(--background-color-skin);
  border: 1px solid #66b1ff;
  min-width: 2.62rem;
  min-height: 2.67rem;
  color: var(--font-color-skin);
  // background-size: 100% 100%;
  font-size: 0.16rem;
  margin-left: 0.1rem;
  overflow: visible;
  position: relative;
}
.markbox.min {
  min-width: 2.5rem;
  width: 2.5rem;
}
.tt {
  color: #01d8ff;

}
::v-deep {
    .el-divider {
      background-color: #01d8ff;
      margin-left: 0;
    }
  }
.markTitle {
  height: 0.42rem;
  display: flex;
  line-height: 1;
  justify-content: space-between;
  align-items: center;
  background: rgba(1, 216, 255, 0.1);

  p {
    display: flex;
    line-height: 1;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0.2rem;
    color: #409eff;
    img {
      margin-right: 0.1rem;
      height: 0.16rem;
      width: auto;
    }
  }
  i {
    font-size: 0.2rem;
    color: #679db4;
    cursor: pointer;
    margin-right: 0.1rem;
  }
  i:hover {
    color: #548aa1;
  }
}

.Feature {
  margin: 0 0.2rem;
  line-height: 0.28rem;
  margin-top: 0.08rem;
  margin-bottom: 0.12rem;
  width: 3rem;
}
</style>
